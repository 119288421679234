/* @media screen and (max-width: 600px) {
  body {
    min-height: 100vh;
    min-height: fill-available;
    min-height: -webkit-fill-available;
  }
  html {
    height: fill-available;
    height: -webkit-fill-available;
  }
  #root{
    min-height: 100vh;
    min-height: fill-available;
    min-height: -webkit-fill-available;
  }
  .mobHeight{
    min-height: 100vh;
    height: fill-available;
    height: -webkit-fill-available;
  }
  
} */

body,html,#root,.App {
  height: 100%;
}